import { XingletWrapper } from './xinglet-helpers';
import type { XingletInterface } from './xinglet-helpers';

export type FrameProps = {
  children: React.ReactNode;
  hideFooter?: boolean;
  noMainContentWrapperStyles?: boolean;
  isEditorPage?: boolean;
  className?: string;
};

export type FrameXinglet = XingletInterface<FrameProps>;
export const Frame = XingletWrapper<FrameProps>(
  '@xing-com/crate-lebenslauf-components-frame'
);
