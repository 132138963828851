import styled from 'styled-components';

import { Carousel } from '@xing-com/carousel';
import { Button } from '@xing-com/crate-lebenslauf-button';
import { mainContentWrapperStyles } from '@xing-com/crate-lebenslauf-components';
import {
  SingleSizeIllustrationHeroFeedbackHub,
  SingleSizeIllustrationHeroCelebration,
} from '@xing-com/illustrations';
import {
  mediaConfined,
  mediaWideNavless,
  mediaTiny,
} from '@xing-com/layout-tokens';
import {
  spaceS,
  spaceM,
  spaceL,
  spaceXL,
  spaceXXL,
  space3XL,
  space5XL,
  space4XL,
  space6XL,
  scale030,
  scale150,
  scale180,
  scale230,
  scale340,
  scale240,
  scale260,
  scale280,
} from '@xing-com/tokens';
import { BodyCopy, Headline } from '@xing-com/typography';

export const Wrapper = styled.div`
  ${mainContentWrapperStyles}
  padding-top: 0;

  @media ${mediaConfined} {
    padding-top: 0;
  }

  @media ${mediaWideNavless} {
    padding-top: 0;
  }
`;

export const FullWidthWrapper = styled.div<{ $backgroundColor: string }>`
  transition: background-color 200ms;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  padding: 0 ${spaceL};
  padding-top: ${scale240};

  @media ${mediaConfined} {
    padding-top: ${scale260};
  }

  @media ${mediaWideNavless} {
    padding-top: ${scale280};
  }
`;

export const StyledSection = styled.section<{
  $alignment?: string;
  $isHeroSection?: boolean;
}>`
  max-width: ${({ $isHeroSection }) => ($isHeroSection ? '1500px' : '1024px')};
  margin: 0 auto;
  margin-bottom: ${space6XL};

  ${({ $alignment }) => `
    text-align: ${$alignment ? $alignment : 'center'};
  `}
`;

export const StyledCTA = styled(Button)`
  margin-bottom: ${space4XL};

  @media ${mediaWideNavless} {
    margin-top: ${spaceS};
  }
`;

export const StyledHeadline = styled(Headline)`
  @media ${mediaConfined} {
    font-size: ${scale150};
  }

  margin-bottom: ${spaceXL};
`;

export const StyledSteps = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: ${space5XL};
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media ${mediaConfined} {
    flex-wrap: nowrap;
  }
`;

export const StyledStep = styled.li`
  display: inline-flex;
  padding: 0 ${spaceS};
  max-width: 50%;
  align-items: center;
  flex-direction: column;

  @media ${mediaConfined} {
    max-width: ${scale340};
  }
`;

export const StyledIconCopy = styled(BodyCopy)`
  margin-top: ${spaceXL};
  font-weight: bold;
`;

export const StyledIllustrationSection = styled(StyledSection)`
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  @media ${mediaConfined} {
    flex-direction: row;
  }
`;

export const StyledIllustration = styled(SingleSizeIllustrationHeroCelebration)`
  max-width: 100%;
  height: auto;
  flex-shrink: 0;

  @media ${mediaConfined} {
    margin-left: ${space3XL};
  }

  @media ${mediaWideNavless} {
    margin-left: ${scale230};
  }
`;

export const StyledIllustrationLeft = styled(
  SingleSizeIllustrationHeroFeedbackHub
)`
  max-width: 100%;
  height: auto;
  flex-shrink: 0;

  @media ${mediaConfined} {
    margin-right: ${space3XL};
    order: -1;
  }

  @media ${mediaWideNavless} {
    margin-right: ${scale230};
  }
`;

export const StyledStepsCopy = styled(BodyCopy)`
  margin-bottom: ${spaceXXL};
`;

export const StyledAnimationWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 281px;
  height: 235px;

  @media ${mediaConfined} {
    margin-right: ${space3XL};
    order: -1;
  }

  @media ${mediaWideNavless} {
    margin-right: ${scale230};
    width: 470px;
    height: 394px;
  }
`;

export const StyledAnimation = styled.img<{ $isActive: boolean }>`
  transition: opacity 0.5s ease-in-out;
  position: absolute;
  max-width: 100%;
  height: auto;
  opacity: 0;

  ${({ $isActive }) => `
    opacity: ${$isActive ? 1 : 0};
  `}
`;

export const StyledAnimationStep = styled.div<{ $isActive: boolean }>`
  transition: opacity 0.5s ease-in-out;

  ${({ $isActive }) => `
    opacity: ${$isActive ? 1 : 0.3};
  `}
`;

export const StyledGoogleBadge = styled.img`
  width: auto;
  height: 60px;
  margin: 0 ${scale030};
`;

export const StyledAppleBadge = styled.img`
  width: auto;
  height: 60px;
  margin: 0 ${scale030};
`;

export const StyledAppsHeadline = styled(Headline)`
  font-size: ${scale150};

  @media ${mediaConfined} {
    font-size: ${scale180};
  }
`;

export const StyledArticles = styled.div`
  margin: ${spaceL} -${spaceM} -${spaceM} -${spaceM};
  display: none;

  @media ${mediaWideNavless} {
    display: block;
  }

  > * {
    margin: ${spaceM};
  }
`;

export const StyledCarousel = styled(Carousel)`
  @media (max-width: 1024px) {
    margin: 0 -${spaceL};
    padding-left: ${spaceL};
    padding-right: ${spaceL};
  }

  @media ${mediaWideNavless} {
    display: none;
  }
`;

export const TemplatesPreview = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: -10px;
  display: none;

  @media ${mediaTiny} {
    display: block;
  }
`;
