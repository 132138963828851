import type React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@xing-com/crate-lebenslauf-button';
import { useBasePath, useVitaTracking } from '@xing-com/crate-lebenslauf-hooks';
import { mediaWideNavless } from '@xing-com/layout-tokens';
import {
  xdlColorBackgroundSecondary,
  space5XL,
  space4XL,
  space3XL,
  space6XL,
  spaceXL,
  spaceS,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

import { StyledHeadline } from './cv-startpage.styled';

const StyledSection = styled('section')`
  display: flex;
  background-color: ${xdlColorBackgroundSecondary};
  padding: ${spaceXL};
  gap: ${spaceXL};
  margin: 0 auto ${space6XL} auto;
  max-width: 1150px;
  flex-direction: column;
  text-align: center;
  @media ${mediaWideNavless} {
    text-align: left;
    flex-direction: row;
    gap: ${space3XL};
    padding: ${space5XL} ${space4XL};
  }
`;

const StyledDiv = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: ${spaceXL};
  align-items: center;
  @media ${mediaWideNavless} {
    display: block;
  }
`;

const StyledButton = styled(Button)`
  @media ${mediaWideNavless} {
    margin-top: ${spaceS};
  }
`;

export const CoverLetterSection: React.FC = () => {
  const basePath = useBasePath();
  const { trackWithNWT } = useVitaTracking({ pageName: 'home' });

  const trackingNwtCreateCoverLetter = (): void => {
    trackWithNWT({
      event: 'opened',
      element: 'create_cover_letter',
    });
  };

  return (
    <StyledSection>
      <StyledHeadline size="xxxlarge">
        <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_COVER_LETTER_HEADLINE" />
      </StyledHeadline>
      <StyledDiv>
        <BodyCopy size="large">
          <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_COVER_LETTER_SUBLINE" />
        </BodyCopy>
        <StyledButton
          variant="primary"
          size="medium"
          onClick={trackingNwtCreateCoverLetter}
          to={`${basePath}/anschreiben?sc_o=lebenslauf_home_create_cover_letter_click&sc_o_PropActionOrigin=lebenslauf_home_body`}
        >
          <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_COVER_LETTER_CTA" />
        </StyledButton>
      </StyledDiv>
    </StyledSection>
  );
};
