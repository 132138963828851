import { css } from 'styled-components';

import { mediaConfined, mediaWideNavless } from '@xing-com/layout-tokens';
import {
  spaceL,
  spaceXL,
  space3XL,
  space4XL,
  scale280,
  scale260,
  scale210,
} from '@xing-com/tokens';

export const mainContentWrapperStyles = css`
  padding: ${scale210} ${spaceL} ${spaceXL} ${spaceL};
  position: relative;

  @media ${mediaConfined} {
    padding: ${scale260} ${spaceL} ${space3XL} ${spaceL};
  }

  @media ${mediaWideNavless} {
    padding: ${scale280} ${space4XL} ${space4XL} ${space4XL};
    max-width: calc(1390px + ${space4XL} + ${space4XL});
    margin: 0 auto;
  }
`;
