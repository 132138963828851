import type * as React from 'react';
import { Helmet } from 'react-helmet-async';

import { useLoginState } from '@xing-com/crate-hooks-use-login-state';

type MetaDataProps = { title?: string; description?: string };

export const MetaData: React.VFC<MetaDataProps> = ({ title, description }) => {
  const { isLoggedOut } = useLoginState();

  return (
    <Helmet htmlAttributes={isLoggedOut ? { class: 'light' } : {}}>
      <meta name="robots" content="index,follow" />

      {title ? <title>{title}</title> : null}
      {title ? <meta name="og:title" content={title} /> : null}
      {title ? <meta property="twitter:title" content={title} /> : null}

      {description ? <meta name="description" content={description} /> : null}
      {description ? (
        <meta property="og:description" content={description} />
      ) : null}
      {description ? (
        <meta name="twitter:description" content={description} />
      ) : null}
    </Helmet>
  );
};
