import image14 from './assets/alles-was-du-ueber-anlagen-in-der-bewerbung-wissen-musst.jpeg';
import image21 from './assets/anonyme-bewerbung-ein-schritt-in-richtung-chancengleichheit.jpg';
import anschreibenAufbau from './assets/anschreiben-aufbau.jpeg';
import arbeitsvertrag from './assets/arbeitsvertrag.jpeg';
import aufloesung from './assets/aufloesung.jpeg';
import image17 from './assets/auslandsaufenthalt-im-lebenslauf-wie-du-deine-internationale-erfahrung-optimal-p.jpg';
import ausserordentlich from './assets/ausserordentlich.jpeg';
import image23 from './assets/bewerbung-zurueckziehen-eine-entscheidung-mit-fingerspitzengefuehl.jpg';
import bewerbungsfoto from './assets/bewerbungsfoto.jpg';
import bewerbungsmappeAufbau from './assets/bewerbungsmappe-aufbau.jpeg';
import bewerbungsschreibenAusbildung from './assets/bewerbungsschreiben-ausbildung.jpeg';
import bewerbungsschreibenPraktikum from './assets/bewerbungsschreiben-praktikum.jpeg';
import image22 from './assets/das-bewerbungsverfahren-ablauf-und-tipps.jpg';
import image3 from './assets/das-perfekte-bewerbungsschreiben-au.jpeg';
import image4 from './assets/das-ultimative-handbuch-fuer-empfeh.jpeg';
import image7 from './assets/deckblatt-fuer-die-bewerbung-gestal.jpeg';
import image19 from './assets/der-perfekte-lebenslauf-welche-persoenlichen-daten-gehoeren-hinein.jpg';
import image5 from './assets/der-perfekte-schlusssatz-fuer-deine.jpeg';
import image18 from './assets/der-ultimative-guide-zur-kurzbewerbung-was-wie-und-warum.jpg';
import image11 from './assets/der-ultimative-leitfaden-fuer-deine-online-bewerbung-1.jpeg';
import image26 from './assets/der-umfassende-leitfaden-zum-telefoninterview-was-du-wissen-musst.jpg';
import image13 from './assets/die-kunst-der-initiativbewerbung-wie-du-dich-erfolgreich-selbst-ins-spiel-brings.jpeg';
import image12 from './assets/edv-kenntnisse-im-lebenslauf-wie-du-deine-digitalen-faehigkeiten-praesentierst.jpeg';
import elternzeitImLebenslauf from './assets/elternzeit-im-lebenslauf.jpg';
import image10 from './assets/erfolgreich-bewerben-wie-du-deine-soft-skills-effektiv-praesentierst.jpeg';
import image1 from './assets/erfolgreiche-bewerbungsmappe-so-ueb-1.jpeg';
import image9 from './assets/erfolgreiche-interne-bewerbung-tipp.jpeg';
import image15 from './assets/erfolgreicher-wiedereinstieg-und-berufliche-neuorientierung-tipps-und-strategien.jpeg';
import image2 from './assets/erfolgreiches-motivationsschreiben-5.jpeg';
import faehigkeitenLebenslauf from './assets/faehigkeiten-lebenslauf.jpg';
import feedback from './assets/feedback.jpeg';
import headhunter from './assets/headhunter.jpeg';
import hobbysLebenslauf from './assets/hobbys-lebenslauf.jpg';
import individuellerLebenslauf from './assets/individueller-lebenslauf.jpg';
import kuendigung from './assets/kuendigung.jpeg';
import lebenslaufAufbauInhalt from './assets/lebenslauf-aufbau-inhalt.jpg';
import lebenslaufAusbildung from './assets/lebenslauf-ausbildung.jpg';
import lebenslaufBerufserfahrung from './assets/lebenslauf-berufserfahrung.jpg';
import lebenslaufEnglisch from './assets/lebenslauf-englisch.jpg';
import lebenslaufLuecken from './assets/lebenslauf-luecken.jpg';
import lebenslaufSchueler from './assets/lebenslauf-schueler.jpg';
import lebenslaufUnterschreiben from './assets/lebenslauf-unterschreiben.jpg';
import image6 from './assets/meistere-deinen-jobwechsel-wie-du-d.jpeg';
import image24 from './assets/mit-stil-verzichten-vorstellungsgespraech-absagen.jpg';
import praktikumsbericht from './assets/praktikumsbericht.jpeg';
import probezeit from './assets/probezeit.jpeg';
import reihenfolgeBewerbung from './assets/reihenfolge-bewerbung.jpeg';
import schlusssatzBewerbung from './assets/schlusssatz-bewerbung.jpg';
import sprachkenntnisseLebenslauf from './assets/sprachkenntnisse-lebenslauf.jpg';
import tabellarischerLebenslauf from './assets/tabellarischer-lebenslauf.jpg';
import test from './assets/test.jpeg';
import tippsBewerbungsschreiben from './assets/tipps-bewerbungsschreiben.jpeg';
import image8 from './assets/uberzeugender-einleitungssatz-so-ge.jpeg';
import image25 from './assets/vom-ersten-wort-an-ueberzeugen-die-richtige-schriftart-fuer-deine-bewerbung.jpg';
import image20 from './assets/von-selbststaendig-zu-unwiderstehlich-so-praesentierst-du-dich-im-lebenslauf.jpg';
import image16 from './assets/vorstellungsgespraech-erfolgreich-meistern-tipps-zur-vorbereitung-und-souveraene.jpeg';

export type ArticleType = {
  id: string;
  url: string;
  title: string;
  description: string;
  image: {
    srcWide: any;
  };
  isTopArticle?: boolean;
  domain?: string;
};

export const articles: ArticleType[] = [
  {
    id: '298751.dda06d',
    url: '/lebenslauf/ratgeber/erfolgreiche-bewerbungsmappe-so-ueb-1',
    title:
      'Erfolgreiche Bewerbungsmappe: So überzeugst Du mit Struktur & Inhalt',
    description:
      'Eine überzeugende Bewerbungsmappe ist der Schlüssel zum Erfolg bei der Jobsuche. Sie dient als erste Visitenkarte und vermittelt dem Arbeitgeber einen professionellen Eindruck von dir und deinen Fähigkeiten. Doch wie erstellst du eine solche Mappe, die sowohl inhaltlich als auch optisch überzeugt und alle wichtigen Bewerbungsunterlagen enthält?',
    image: {
      srcWide: image1,
    },
    isTopArticle: true,
    domain: 'xing',
  },
  {
    id: '298886.812fcf',
    url: '/lebenslauf/ratgeber/erfolgreiches-motivationsschreiben-5',
    title:
      'Erfolgreiches Motivationsschreiben: Tipps und Schritt-für-Schritt-Anleitung',
    description:
      'Ein Motivationsschreiben ist ein wichtiges Dokument, das oft Teil einer Bewerbung ist und deine persönliche Motivation sowie deine Eignung für eine bestimmte Position oder ein Studienprogramm darlegt. In verschiedenen Bereichen kann ein überzeugendes Motivationsschreiben den Unterschied ausmachen.',
    image: {
      srcWide: image2,
    },
    isTopArticle: true,
    domain: 'xing',
  },
  {
    id: '298910.c8e426',
    url: '/lebenslauf/ratgeber/das-perfekte-bewerbungsschreiben-au',
    title:
      'Das perfekte Bewerbungsschreiben: Aufbau, Tipps und Fehlervermeidung für deinen Erfolg im Bewerbungsprozess',
    description:
      'Ein überzeugendes Bewerbungsschreiben kann den entscheidenden Unterschied ausmachen, ob du zu einem Vorstellungsgespräch eingeladen wirst oder nicht. In diesem Artikel erfährst du, wie du den idealen Aufbau eines Bewerbungsschreibens gestaltest.',
    image: {
      srcWide: image3,
    },
    isTopArticle: true,
    domain: 'xing',
  },
  {
    id: '298911.3d13db',
    url: '/lebenslauf/ratgeber/das-ultimative-handbuch-fuer-empfeh',
    title:
      'Das ultimative Handbuch für Empfehlungsschreiben – Alles, was Du wissen musst',
    description:
      'Empfehlungsschreiben sind heutzutage ein wichtiger Bestandteil bei Bewerbungen – egal, ob es sich um eine berufliche Stelle, ein Studium oder ein Stipendium handelt. Sie geben potenziellen Arbeitgebern oder Bildungseinrichtungen einen tieferen Einblick in deine Fähigkeiten, Leistungen und persönlichen Eigenschaften.',
    image: {
      srcWide: image4,
    },
    domain: 'xing',
  },
  {
    id: '298912.51f19b',
    url: '/lebenslauf/ratgeber/der-perfekte-schlusssatz-fuer-deine',
    title: 'Schlusssatz Bewerbung: So beendest Du Dein Anschreiben überzeugend',
    description:
      'In einer Bewerbung kommt es auf jedes Detail an – vor allem auf den Schlusssatz. Er ist das letzte Element, das Personalverantwortliche lesen, bevor sie eine Entscheidung über deine Bewerbung treffen. Daher ist es wichtig, dass du genau weißt, wie du diesen Teil deines Anschreibens gestaltest, um den besten Eindruck zu hinterlassen.',
    image: {
      srcWide: image5,
    },
    isTopArticle: true,
    domain: 'xing',
  },
  {
    id: '298993.bfb118',
    url: '/lebenslauf/ratgeber/meistere-deinen-jobwechsel-wie-du-d',
    title: 'Jobwechsel in Planung? Unsere Tipps für den reibungslosen Ablauf',
    description:
      'In der dynamischen Arbeitswelt von heute ist ein Jobwechsel keine Seltenheit mehr. Tatsächlich kann es viele Gründe geben, die Berufsspur zu wechseln – sei es aus persönlicher Entwicklung, beruflichem Aufstieg oder einfach aus dem Wunsch nach Veränderung. Genau hier setzt unser Thema an: Die Begründung eines Jobwechsels und dessen Bedeutung.',
    image: {
      srcWide: image6,
    },
    domain: 'xing',
  },
  {
    id: '298959.725864',
    url: '/lebenslauf/ratgeber/deckblatt-fuer-die-bewerbung-gestal',
    title: 'Perfektes Deckblatt für Deine Bewerbung: Top Tipps und Beispiele',
    description:
      'Erfahre, wie du ein ansprechendes Deckblatt für deine Bewerbung erstellst und mit einer professionellen Gestaltung den Grundstein für den Erfolg deiner Bewerbung legst. Entdecke hilfreiche Tipps und Tricks, um dich von der Konkurrenz abzuheben.',
    image: {
      srcWide: image7,
    },
    isTopArticle: true,
    domain: 'xing',
  },
  {
    id: '298969.844888',
    url: '/lebenslauf/ratgeber/uberzeugender-einleitungssatz-so-ge',
    title: 'Perfekter Einleitungssatz in der Bewerbung: Unsere Experten-Tipps',
    description:
      'Der erste Eindruck zählt – das gilt auch für Bewerbungen. Der Einleitungssatz ist dabei von besonderer Bedeutung, denn er ist das Erste, was Personaler von deinem Anschreiben lesen. Wenn du ihre Aufmerksamkeit direkt zu Beginn gewinnst, erhöhst du die Chancen, dass dein Bewerbungsschreiben positiv aufgenommen wird.',
    image: {
      srcWide: image8,
    },
    domain: 'xing',
  },
  {
    id: '298976.d85ab7',
    url: '/ratgeber/interne-bewerbung',
    title: 'Interne Bewerbung: Deine Chance auf eine Beförderung nutzen',
    description:
      'Interne Bewerbungen spielen eine bedeutende Rolle sowohl für Arbeitnehmer als auch für Unternehmen. Sie ermöglichen es den Mitarbeitern, ihre Karriere voranzutreiben, neue Herausforderungen innerhalb des Unternehmens anzunehmen und ihre Fähigkeiten und Erfahrungen bestmöglich einzusetzen.',
    image: {
      srcWide: image9,
    },
    domain: 'lebenslauf',
  },
  {
    id: '299378.f17b0b',
    url: '/lebenslauf/ratgeber/erfolgreich-bewerben-wie-du-deine-soft-skills-effektiv-praesentierst',
    title: 'Wie Du Soft Skills in der Bewerbung erfolgreich einsetzt',
    description:
      'In der heutigen Arbeitswelt sind Soft Skills nicht mehr nur ein nettes Extra - sie sind ein entscheidender Faktor für deinen beruflichen Erfolg. Sie sind das, was dich als Individuum ausmacht und dich von anderen Bewerbern abhebt.',
    image: {
      srcWide: image10,
    },
    domain: 'xing',
  },
  {
    id: '299374.023738',
    url: '/lebenslauf/ratgeber/der-ultimative-leitfaden-fuer-deine-online-bewerbung-1',
    title: 'Online Bewerbung: So überzeugst Du jeden Arbeitgeber',
    description:
      'In der heutigen digitalen Welt ist die Online-Bewerbung zum Standard geworden. Sie ist schnell, effizient und ermöglicht es dir, dich auf Stellenangebote auf der ganzen Welt zu bewerben. Aber wie schreibst du eine Online-Bewerbung richtig?',
    image: {
      srcWide: image11,
    },
    domain: 'xing',
  },
  {
    id: '299373.ee8ba5',
    url: '/lebenslauf/ratgeber/edv-kenntnisse-im-lebenslauf-wie-du-deine-digitalen-faehigkeiten-praesentierst',
    title: 'EDV-Kenntnisse im Lebenslauf: So stichst Du hervor',
    description:
      'Entdecke, wie du EDV-Kenntnisse im Lebenslauf darstellst, um deine Karrierechancen zu verbessern. Lerne, was gute Computerkenntnisse ausmacht und wie du sie erweiterst.',
    image: {
      srcWide: image12,
    },
    domain: 'xing',
  },
  {
    id: '299309.fc4678',
    url: '/lebenslauf/ratgeber/alles-was-du-ueber-anlagen-in-der-bewerbung-wissen-musst',
    title: 'Anlagen in der Bewerbung: Dein Leitfaden zum Erfolg',
    description:
      'Die richtigen Anlagen können den Unterschied ausmachen, wenn es um deine Bewerbung geht. Aber welche Dokumente gehören dazu und wie präsentierst du sie am besten? In diesem Artikel erhältst du einen umfassenden Leitfaden zu Anlagen in der Bewerbung.',
    image: {
      srcWide: image14,
    },
    domain: 'xing',
  },
  {
    id: '299298.70c4ae',
    url: '/lebenslauf/ratgeber/erfolgreicher-wiedereinstieg-und-berufliche-neuorientierung-tipps-und-strategien',
    title: 'Wiedereinstieg: Dein Wegweiser zum beruflichen Neustart',
    description:
      'Der Wiedereinstieg in den Beruf nach einer Pause und die berufliche Neuorientierung sind wichtige Themen, die in der heutigen Arbeitswelt zunehmend an Bedeutung gewinnen. Im Laufe eines Arbeitslebens kann es verschiedene Gründe geben, warum man eine Pause einlegt oder sich für eine neue berufliche Richtung entscheidet.',
    image: {
      srcWide: image15,
    },
    domain: 'xing',
  },
  {
    id: '299297.a35a27',
    url: '/ratgeber/vorstellungsgespraech-erfolgreich-meistern',
    title:
      'Das Vorstellungsgespräch: Vorbereitung ist Dein Schlüssel zum Erfolg',
    description:
      'Ein Vorstellungsgespräch kann eine herausfordernde Hürde auf dem Weg zu deinem Traumjob sein. Es ist der Moment, in dem du die Chance hast, dich als idealen Kandidaten zu präsentieren, und gleichzeitig das Unternehmen besser kennenlernst.',
    image: {
      srcWide: image16,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: 'das-perfekte-bewerbungsfoto-so-bindest-du-das-foto-in-deinen-lebenslauf-ein',
    id: '300915.60b439',
    url: '/ratgeber/bewerbungsfoto',
    title:
      'Das perfekte Bewerbungsfoto – so bindest Du das Foto in Deinen Lebenslauf ein',
    description:
      'Das Bewerbungsfoto ist ein umstrittenes Thema bei der Erstellung einer Bewerbung. Vielleicht fragst auch Du Dich, ob es überhaupt sinnvoll ist, ein Bewerbungsfoto in den Lebenslauf aufzunehmen oder ob es besser ist, darauf zu verzichten.',
    image: {
      srcWide: bewerbungsfoto,
    },
    isTopArticle: true,
    domain: 'lebenslauf',
  },
  {
    // slug: 'der-tabellarische-lebenslauf',
    id: '300841.15828e',
    url: '/ratgeber/tabellarischer-lebenslauf',
    title: 'Der tabellarische Lebenslauf',
    description:
      'In Deutschland hat sich der tabellarische Lebenslauf schon längst durchgesetzt. Kennst du ihn schon? Vermutlich hast du bereits automatisch das richtige Bild im Kopf, wenn du an einen Lebenslauf denkst. Seine Grundstruktur entspricht einer Tabelle – nur ohne sichtbare Trennlinien.',
    image: {
      srcWide: tabellarischerLebenslauf,
    },
    isTopArticle: true,
    domain: 'lebenslauf',
  },
  {
    // slug: 'erfolgreicher-lebenslauf-fuer-schueler-tipps-und-anleitung-fuer-den-einstieg-ins',
    id: '301206.98a37b',
    url: '/ratgeber/lebenslauf-schueler',
    title: 'Schüler-Lebenslauf gestalten: Erfolgreiche Vorlagen und Tricks',
    description:
      'Du bist Schüler und stehst vor der Herausforderung, deinen ersten Lebenslauf zu schreiben? Keine Sorge, wir verraten dir alles Wichtige, um einen professionellen Lebenslauf zu erstellen, der Personalern ins Auge fällt. Wir geben dir Tipps und zeigen dir, wie du deinen Lebenslauf für ein Schülerpraktikum oder den ersten Job optimal gestaltest.',
    image: {
      srcWide: lebenslaufSchueler,
    },
    isTopArticle: true,
    domain: 'lebenslauf',
  },
  {
    // slug: 'lebenslauf-englisch-tipps-und-tricks-fuer-einen-gelungenen-englischen-cv',
    id: '301186.5d3852',
    url: '/ratgeber/lebenslauf-englisch',
    title:
      'Lebenslauf Englisch: Tipps und Tricks für einen gelungenen englischen CV',
    description:
      'Ein englischer Lebenslauf, auch als CV (Curriculum Vitae) bekannt, ist ein wichtiger Bestandteil bei der Bewerbung für internationale Stellen oder bei Unternehmen, die Englisch als Arbeitssprache nutzen. Um dir bei der Erstellung eines überzeugenden englischen Lebenslaufs zu helfen, haben wir einige Tipps und Tricks zusammengestellt.',
    image: {
      srcWide: lebenslaufEnglisch,
    },
    isTopArticle: true,
    domain: 'lebenslauf',
  },
  {
    // slug: 'lebenslauf-unterschreiben-ja-oder-nein-eine-schritt-fuer-schritt-anleitung-1',
    id: '301113.9871ab',
    url: '/ratgeber/lebenslauf-unterschreiben',
    title:
      'Lebenslauf unterschreiben – ja oder nein? Eine Schritt-für-Schritt-Anleitung',
    description:
      'Dein Lebenslauf ist ein wichtiges Dokument im Bewerbungsprozess und dient als Visitenkarte für potenzielle Arbeitgeber. Doch bevor Dein Lebenslauf seine volle Wirkung entfalten kann, gibt es eine entscheidende letzte Hürde: die Unterschrift. Sie ist ein verbindliches Zeichen dafür, dass die Angaben darin der Wahrheit entsprechen.',
    image: {
      srcWide: lebenslaufUnterschreiben,
    },
    isTopArticle: true,
    domain: 'lebenslauf',
  },
  {
    // slug: 'mit-diesem-schlusssatz-ueberzeugst-du-jeden-arbeitgeber-tipps-fuer-eine-erfolgre',
    id: '301179.6c5d52',
    url: '/ratgeber/schlusssatz-bewerbung',
    title:
      'Mit diesem Schlusssatz überzeugst du jeden Arbeitgeber – Tipps für eine erfolgreiche Bewerbung',
    description:
      'Ein überzeugender Schlusssatz ist entscheidend für Bewerbungserfolg. Letzter Eindruck beim Arbeitgeber zählt. Positiver Abschluss erhöht Einladungschancen zum Vorstellungsgespräch. Schlusssatz betont Motivation und Enthusiasmus für die Stelle.',
    image: {
      srcWide: schlusssatzBewerbung,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: 'der-perfekte-lebenslauf-fuer-die-ausbildung-in-5-schritten-zum-bewerbungsdokumen',
    id: '301208.374dd4',
    url: '/ratgeber/lebenslauf-ausbildung',
    title:
      'Der perfekte Lebenslauf für die Ausbildung: In 5 Schritten zum Bewerbungsdokument',
    description:
      'Ein überzeugender Lebenslauf ist zentral für Bewerbungen, inklusive Ausbildungen. Erfahre hier, wie du einen passenden Lebenslauf für eine Ausbildung erstellst, ob Schulbildung weggelassen werden kann und wie der Berufsschul-Lebenslauf aussieht. Wir zeigen, was hineingehört, und bieten eine hilfreiche Mustervorlage.',
    image: {
      srcWide: lebenslaufAusbildung,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: 'die-bedeutung-von-hobbys-im-lebenslauf-wie-du-deine-interessen-zu-deinem-vorteil-1',
    id: '301116.431d9b',
    url: '/ratgeber/hobbys-lebenslauf',
    title:
      'Die Bedeutung von Hobbys im Lebenslauf: Wie Du Deine Interessen zu Deinem Vorteil nutzen kannst',
    description:
      'Du fragst dich vielleicht, ob Hobbys im Lebenslauf nötig sind. Arbeitgeber interessieren sich nicht nur für Arbeit und Ausbildung, sondern auch für Persönlichkeit. Hobbys bieten Einblicke in Interessen und Eigenschaften, die dich definieren.',
    image: {
      srcWide: hobbysLebenslauf,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: 'sprachkenntnisse-im-lebenslauf-so-praesentierst-du-sie-erfolgreich',
    id: '301342.92a68a',
    url: '/ratgeber/sprachkenntnisse-lebenslauf',
    title: 'Sprachkenntnisse im Lebenslauf präsentieren und optimal punkten!',
    description:
      'In der globalisierten Welt sind Sprachkenntnisse, besonders im Jobmarkt, essenziell. Ein überzeugender Lebenslauf hebt deine Sprachkompetenz hervor, differenziert dich und zeigt Arbeitgebern deine Kommunikationsstärke und Anpassungsfähigkeit.',
    image: {
      srcWide: sprachkenntnisseLebenslauf,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: 'faehigkeiten-im-lebenslauf-so-ueberzeugst-du-mit-deinen-hard-soft-skills',
    id: '301303.e8b04f',
    url: '/ratgeber/faehigkeiten-lebenslauf',
    title:
      'Fähigkeiten im Lebenslauf: So überzeugst Du mit Deinen Hard & Soft Skills',
    description:
      'Fähigkeiten im Lebenslauf sind für Arbeitsmarkt-Erfolg essenziell. Strukturierter CV betont Stärken, hebt hervor und beeinflusst Erfolg. Gute Präsentation steigert Einladungschancen zum Gespräch. Artikel zeigt, wie Fähigkeiten an Anzeigen angepasst werden, für Traumjob-Chancen.',
    image: {
      srcWide: faehigkeitenLebenslauf,
    },
    isTopArticle: true,
    domain: 'lebenslauf',
  },
  {
    // slug: 'aufbau-und-inhalt-eines-perfekten-lebenslaufs',
    id: '301091.a3514e',
    url: '/ratgeber/lebenslauf-aufbau-inhalt',
    title: 'Aufbau und Inhalt eines perfekten Lebenslaufs',
    description:
      'Was muss rein – was bleibt draußen? Bevor Du einen Lebenslauf schreibst, der den Personaler überzeugt, musst Du Dir ein paar Gedanken über Aufbau und Inhalt machen. Denn unnötige Informationen schaden der Qualität des Lebenslaufs fast genauso, wie wenn wichtige Daten fehlen.',
    image: {
      srcWide: lebenslaufAufbauInhalt,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: 'luecken-im-lebenslauf-wie-du-sie-erfolgreich-erklaerst-und-nutzt',
    id: '301280.95c672',
    url: '/ratgeber/lebenslauf-luecken',
    title: 'Lücken im Lebenslauf erklären: So überzeugst Du im Lebenslauf',
    description:
      'Lücken im Lebenslauf sind Zeiträume ohne Berufstätigkeit, Ausbildung oder Weiterbildung. Gründe sind vielfältig: persönliche Umstände, Bildung, Jobwechsel. Lücken haben unterschiedliche Bedeutung und Auswirkungen. Manchmal wirken sie wenig Engagement oder Zielstrebigkeit. Richtig darstellen und erklären ist wichtig, um Arbeitgeber zu überzeugen.',
    image: {
      srcWide: lebenslaufLuecken,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: 'so-praesentierst-du-deine-berufserfahrung-im-lebenslauf-optimal-inklusive-hobbys',
    id: '301301.d878c9',
    url: '/ratgeber/lebenslauf-berufserfahrung',
    title:
      'So präsentierst du deine Berufserfahrung im Lebenslauf optimal – inklusive Hobbys und Tipps für Berufseinsteiger',
    description:
      'Deine Berufserfahrung ist der wahrscheinlich wichtigste Faktor im Lebenslauf. In diesem Artikel erfährst du, wie du diese optimal präsentierst und welche Rolle Hobbys dabei spielen können. Außerdem bekommst du wertvolle Tipps, wenn du als Berufseinsteiger noch wenig Erfahrung vorweisen kannst.',
    image: {
      srcWide: lebenslaufBerufserfahrung,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: 'elternzeit-im-lebenslauf-so-praesentierst-du-deine-familienpause-optimal',
    id: '301341.1dcb13',
    url: '/ratgeber/elternzeit-im-lebenslauf',
    title: 'Clever mit Elternzeit im Lebenslauf umgehen - so klappt es!',
    description:
      'Elternzeit ist bedeutsam im Berufsleben, da sie der Kindererziehung gewidmet ist. Arbeitnehmer reduzieren vorübergehend berufliche Aktivitäten, um sich auf Familie zu fokussieren. Wie stellt man diese Pause im Lebenslauf am besten dar, um bei Arbeitgebern positiv zu wirken?',
    image: {
      srcWide: elternzeitImLebenslauf,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: 'die-top-10-fehler-im-lebenslauf-vermeide-diese-stolperfallen-fuer-eine-erfolgrei',
    id: '301204.48f82b',
    url: '/ratgeber/fehler-im-lebenslauf',
    title:
      'Die Top 10 Fehler im Lebenslauf: Vermeide diese Stolperfallen für eine erfolgreiche Bewerbung!',
    description:
      'Fehler im Lebenslauf können den Unterschied zwischen einer Einladung zum Vorstellungsgespräch und einer Absage bedeuten. In diesem Artikel zeigen wir Dir, welche Fehler Du unbedingt vermeiden solltest, wie gravierend sie sein können und wie Du Deinen Lebenslauf optimierst, um den besten Eindruck bei potenziellen Arbeitgebern zu hinterlassen.',
    image: {
      srcWide: image16,
    },
    domain: 'fehlerImLebenslauf',
  },
  {
    // slug: 'individueller-lebenslauf-wie-du-dich-erfolgreich-von-der-masse-abhebst',
    id: '301306.4a7679',
    url: '/ratgeber/individueller-lebenslauf',
    title:
      'Der individuelle Lebenslauf, der Arbeitgeber begeistert - So geht es!',
    description:
      'Der individuelle Lebenslauf ist heute im Bewerbungsprozess entscheidend. Maßgeschneiderte CVs heben dich von Mitbewerbern ab und steigern Erfolgschancen. Dieser Artikel bietet wertvolle Tipps, um zielgerichtet erfolgreichen Lebenslauf zu gestalten. Anpassung an Stellenanforderungen, Betonung persönlicher Stärken überzeugt Arbeitgeber von Eignung.',
    image: {
      srcWide: individuellerLebenslauf,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: 'auslandsaufenthalt-im-lebenslauf-wie-du-deine-internationale-erfahrung-optimal-p',
    id: '303714.eeed38',
    url: '/ratgeber/auslandsaufenthalt-lebenslauf',
    title: "Auslandsaufenthalt im Lebenslauf als Karriere-Booster - So geht's!",
    description:
      'Entdecke, wie Du Deinen Auslandsaufenthalt im Lebenslauf präsentierst und Deine internationale Erfahrung optimal zur Geltung bringst. Erfahre mehr!',
    image: {
      srcWide: image17,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: 'der-ultimative-guide-zur-kurzbewerbung-was-wie-und-warum',
    id: '303716.c80eb0',
    url: '/ratgeber/kurzbewerbung',
    title:
      "Kurzbewerbung meistern: Kompakt, prägnant, erfolgreich – so geht's!",
    description:
      'Entdecke den ultimativen Guide zur Kurzbewerbung. Lerne, was sie ist, wie sie aussieht und wann du sie nutzen solltest. Erfolg beginnt hier.',
    image: {
      srcWide: image18,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: 'der-perfekte-lebenslauf-welche-persoenlichen-daten-gehoeren-hinein',
    id: '303735.fa991b',
    url: '/ratgeber/persoenliche-daten-im-lebenslauf',
    title: 'Optimale persönliche Daten im Lebenslauf',
    description:
      'Entdecke, welche persönlichen Angeben Du im Lebenslauf präsentieren solltest. Lerne, welche persönlichen Daten zählen und wie Du sie richtig darstellst.',
    image: {
      srcWide: image19,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: 'von-selbststaendig-zu-unwiderstehlich-so-praesentierst-du-dich-im-lebenslauf',
    id: '303737.82a4fb',
    url: '/ratgeber/selbststaendigkeit-im-Lebenslauf',
    title: 'Selbstständigkeit im Lebenslauf: So steigern Sie Ihre Jobchancen',
    description:
      'Entdecke, wie du deine Selbstständigkeit im Lebenslauf präsentierst und jeden Arbeitgeber beeindruckst. Lerne, Erfolge hervorzuheben und Fähigkeiten zu betonen.',
    image: {
      srcWide: image20,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: 'anonyme-bewerbung-ein-schritt-in-richtung-chancengleichheit',
    id: '303746.8d7b37',
    url: '/ratgeber/anonyme-bewerbung',
    title: 'Anonyme Bewerbung: Fairer Start im Job - So gelingt der Einstieg!',
    description:
      'Entdecke die Vorteile und Herausforderungen der anonymen Bewerbung. Erfahre, wie sie Chancengleichheit fördert und Diskriminierung im Bewerbungsprozess verhindert.',
    image: {
      srcWide: image21,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: 'das-bewerbungsverfahren-ablauf-und-tipps',
    id: '303761.980b27',
    url: '/ratgeber/das-bewerbungsverfahren',
    title: 'Bewerbungsverfahren: Leitfaden für eine erfolgreiche Bewerbung',
    description:
      'Alles über das Bewerbungsverfahren: Von der Stellenausschreibung bis zur Einstellung. Tipps, Kosten und Phasen im Überblick.',
    image: {
      srcWide: image22,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: 'bewerbung-zurueckziehen-eine-entscheidung-mit-fingerspitzengefuehl',
    id: '303805.50cc66',
    url: '/ratgeber/bewerbung-zurueckziehen',
    title: 'Bewerbung zurückziehen: Klug handeln und neue Wege entdecken',
    description:
      'Bei der Berufswahl kann es vorkommen, dass man sich doch gegen ein Unternehmen entscheidet oder einen zugesagten Job absagen möchte. Hier findest Du die richtigen Tipps.',
    image: {
      srcWide: image23,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: 'mit-stil-verzichten-vorstellungsgespraech-absagen',
    id: '303828.85aa65',
    url: '/ratgeber/vorstellungsgespraech-absagen',
    title:
      'Vorstellungsgespräch absagen leicht gemacht - Hier erfährst Du wie!',
    description:
      'Du musst ein Vorstellungsgespräch verschieben oder absagen? Das kann unangenehm sein, muss es aber nicht. Diese Tipps helfen, um freundlich und professionell zu bleiben.',
    image: {
      srcWide: image24,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: 'vom-ersten-wort-an-ueberzeugen-die-richtige-schriftart-fuer-deine-bewerbung',
    id: '303848.3946b6',
    url: '/ratgeber/schriftart-bewerbung',
    title: 'Die beste Schriftart für Deine Bewerbung - Professionell punkten!',
    description:
      'Entdecke die perfekte Schriftart für deine Bewerbung! Unsere Expertentipps helfen dir, einen professionellen Eindruck zu hinterlassen und dich von der Masse abzuheben.',
    image: {
      srcWide: image25,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: 'der-umfassende-leitfaden-zum-telefoninterview-was-du-wissen-musst',
    id: '303851.93559f',
    url: '/ratgeber/leitfaden-telefoninterview',
    title:
      'Telefoninterviews leicht gemacht: Hier ist die ultimative Anleitung!',
    description:
      'Verbessere Deine Telefoninterviews: Tipps, Fragen und bewährte Strategien für erfolgreiches Telefonieren. So überzeugst Du spielend!',
    image: {
      srcWide: image26,
    },
    domain: 'lebenslauf',
  },
  {
    id: '299362.21f60a',
    url: '/ratgeber/initiativbewerbung',
    title: 'Initiativbewerbung: So überzeugst Du potenzielle Arbeitgeber',
    description:
      'Du bist auf der Suche nach einer neuen beruflichen Herausforderung, aber die passende Stellenausschreibung fehlt? Kein Problem! Mit einer Initiativbewerbung kannst du proaktiv auf Unternehmen zugehen.',
    image: {
      srcWide: image13,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: schluss-mit-08-15-dein-guide-fuer-das-perfekte-bewerbungsschreiben-zum-praktikum
    id: '309901.587c1f',
    url: '/ratgeber/bewerbungsschreiben-praktikum',
    title: 'Erfolgreiche Praktikumsbewerbung: Dein Wegweiser zum Ziel!',
    description:
      'Sichere Dir Deinen Praktikumsplatz mit einem Bewerbungsschreiben, das überzeugt. Hol Dir jetzt die besten Tipps für Deinen Erfolg auf Bewerbung.com!',
    image: {
      srcWide: bewerbungsschreibenPraktikum,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: deine-eintrittskarte-ins-berufsleben-so-rockst-du-das-bewerbungsschreiben-fuer-d
    id: '309890.02dbeb',
    url: '/ratgeber/bewerbungsschreiben-ausbildung',
    title: "Mit Top-Bewerbungsschreiben zur Ausbildung: So gelingt's!",
    description:
      'Mach das Rennen um Deinen Ausbildungsplatz mit einem Anschreiben, das überzeugt. Unverwechselbare Tipps für Deinen Erfolg – hol Dir jetzt den Guide!',
    image: {
      srcWide: bewerbungsschreibenAusbildung,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: die-kunst-der-perfekten-bewerbungsmappe-eroberte-den-jobmarkt
    id: '309850.b85ebc',
    url: '/ratgeber/bewerbungsmappe-aufbau',
    title: 'Effektiver Aufbau: Eine Bewerbungsmappe, die überzeugt!',
    description:
      'So baust Du eine Bewerbungsmappe auf, die überzeugt: Professionell, strukturiert, erfolgreich. Hol Dir die besten Tipps für eine beeindruckende Bewerbung.',
    image: {
      srcWide: bewerbungsmappeAufbau,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: anschreiben-aufbau-deine-schritt-fuer-schritt-anleitung-zur-perfekten-bewerbung
    id: '310019.53d3fe',
    url: '/ratgeber/anschreiben-aufbau',
    title: 'Perfekter Aufbau für das Anschreiben - Bewerbungstipps',
    description:
      'Hier lernst Du, wie ein überzeugendes Anschreiben aufgebaut wird – mit unserer Experten-Anleitung stehst dir bei deiner nächsten Bewerbung ganz vorn!',
    image: {
      srcWide: anschreibenAufbau,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: knacke-den-job-code-bewerbungsschreiben-die-begeistern
    id: '310020.8cc585',
    url: '/ratgeber/tipps-bewerbungsschreiben',
    title: 'Bewerbungsschreiben: Unschlagbare Tipps & Tricks!',
    description:
      'Meistere Dein Bewerbungsschreiben mit unseren Expertentipps. Öffne die Türen zu Deinem Traumjob mit einem Anschreiben, das heraussticht!',
    image: {
      srcWide: tippsBewerbungsschreiben,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: der-durchbruch-so-sortierst-du-deine-bewerbungsunterlagen-zum-erfolg
    id: '310021.9faf65',
    url: '/ratgeber/reihenfolge-bewerbung',
    title: 'Die optimale Reihenfolge Deiner Bewerbung - Auf zum Traumjob!',
    description:
      'Meistere die Reihenfolge Deiner Bewerbung für maximalen Erfolg! Unser Leitfaden bringt Deine Dokumente in die richtige Ordnung und Dir den Job näher.',
    image: {
      srcWide: reihenfolgeBewerbung,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: das-kuendigungsschreiben-so-gelingt-dir-der-elegante-job-exit
    id: '312670.fa59e9',
    url: '/ratgeber/das-kuendigungsschreiben',
    title: 'Kündigungsschreiben: Der elegante Job-Exit in 5 Schritten',
    description:
      'Erfahre, wie Du Dein Kündigungsschreiben professionell und stilvoll verfasst. Nutze die Tipps für einen reibungslosen Übergang zu Deinem nächsten Karriereschritt!',
    image: {
      srcWide: kuendigung,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: arbeitsvertrag-wie-du-schon-vor-dem-ersten-tag-weichen-stellst
    id: '312777.1daf56',
    url: '/ratgeber/der-arbeitsvertrag',
    title:
      'Der perfekte Arbeitsvertrag: So startest Du optimal in Deine Karriere',
    description:
      'Erfahre, wie Du mit dem richtigen Arbeitsvertrag Deine Karriere von Anfang an erfolgreich gestaltest und welche wichtigen Punkte Du beachten solltest.',
    image: {
      srcWide: arbeitsvertrag,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: der-aufloesungsvertrag-was-ihn-ausmacht-und-worauf-du-achten-musst
    id: '312781.bb4671',
    url: '/ratgeber/der-aufloesungsvertrag',
    title: 'Der Auflösungsvertrag: Alles, was Du wissen musst!',
    description:
      'Erfahre, warum ein Auflösungsvertrag ein eleganter Ausstieg sein kann, welche Vor- und Nachteile er hat, und wie Du Deinen beruflichen Übergang optimal gestalten kannst.',
    image: {
      srcWide: aufloesung,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: feedback-geben-wer-sich-konstruktiv-erklaeren-moechte-muss-ein-paar-dinge-beacht
    id: '312785.1510cd',
    url: '/ratgeber/richtig-feedback-geben',
    title: '5 goldene Regeln für konstruktives Feedback: Das musst du beachten',
    description:
      'Erfahre, wie du mit konkreten Beispielen und einer positiven Haltung wirksames Feedback geben kannst und so zur Weiterentwicklung des Teams beiträgst.',
    image: {
      srcWide: feedback,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: mit-headhuntern-zum-erfolg-wie-sie-dein-berufsleben-veraendern-koennenwenn-du-na
    id: '312786.081010',
    url: '/ratgeber/mit-headhuntern-zum-erfolg',
    title: 'Headhunter-Tipps: Strategien für beruflichen Erfolg',
    description:
      'Entdecke, wie Headhunter Dein Berufsleben verändern können und erfahre, wann der richtige Zeitpunkt für die Zusammenarbeit ist. Alle Infos hier!',
    image: {
      srcWide: headhunter,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: praktikumsbericht-professionell-verfasst-so-glaenzt-du-mit-deinen-einblicken
    id: '312788.a697e9',
    url: '/ratgeber/der-praktikumsbericht',
    title:
      'Erfolgreicher Praktikumsbericht: Tipps für einen überzeugenden Bericht',
    description:
      'Mit einem professionell verfassten Praktikumsbericht präsentierst du deine Einblicke mit Überzeugungskraft. Alle Infos gibt es hier!',
    image: {
      srcWide: praktikumsbericht,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: vom-persoenlichkeitstest-zum-job-der-wirklich-zu-dir-passt
    id: '312797.38a9a0',
    url: '/ratgeber/persoenlichkeitstest',
    title: 'Finde den richtigen Job mit dem perfekten Persönlichkeitstest',
    description:
      'Erfahre, wie Persönlichkeitstests dir helfen, den idealen Job zu finden. Welcher Test ist der passende für dich und wie nutzt du die Ergebnisse am besten für deine Karriere?',
    image: {
      srcWide: test,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: ausserordentliche-kuendigung-was-ist-das-eigentlich-und-was-muss-man-beachten
    id: '312811.14333f',
    url: '/ratgeber/ausserordentliche-kuendigung',
    title:
      'Alles über außerordentliche Kündigung: Rechte, Fristen & Handlungsmöglichkeiten',
    description:
      'Erfahre, was eine außerordentliche Kündigung ist, wann sie möglich ist und wie Du richtig handelst. Gratis Muster-Kündigung inklusive!',
    image: {
      srcWide: ausserordentlich,
    },
    domain: 'lebenslauf',
  },
  {
    // slug: die-probezeit-erfolgreich-meistern-wichtige-tipps-und-infos
    id: '312823.68de29',
    url: '/ratgeber/die-probezeit',
    title: 'Probezeit erfolgreich gestalten: Alle wichtigen Tipps und Infos!',
    description:
      'Erfahre alles zur gesetzlichen Probezeit, bewährte Tipps und mögliche Gründe für das Scheitern. Erfolgreich in die neue Karriere starten!',
    image: {
      srcWide: probezeit,
    },
    domain: 'lebenslauf',
  },
];

export const advisoryArticles: ArticleType[] = [
  '300841.15828e',
  '300915.60b439',
  '301113.9871ab',
  '301116.431d9b',
  '301179.6c5d52',
  '301186.5d3852',
  '301204.48f82b',
  '301206.98a37b',
  '301208.374dd4',
  '301280.95c672',
  '301301.d878c9',
  '301303.e8b04f',
  '301306.4a7679',
  '301341.1dcb13',
  '301342.92a68a',
  '301091.a3514e',
  '303714.eeed38',
  '303716.c80eb0',
  '303735.fa991b',
  '303737.82a4fb',
  '303746.8d7b37',
  '303761.980b27',
  '303805.50cc66',
  '303828.85aa65',
  '303848.3946b6',
  '303851.93559f',
  '299297.a35a27',
  '298976.d85ab7',
  '299362.21f60a',
  '309901.587c1f',
  '309890.02dbeb',
  '309850.b85ebc',
  '310019.53d3fe',
  '310020.8cc585',
  '310021.9faf65',
  '312670.fa59e9',
  '312777.1daf56',
  '312781.bb4671',
  '312785.1510cd',
  '312786.081010',
  '312788.a697e9',
  '312797.38a9a0',
  '312811.14333f',
  '312823.68de29',
]
  .map((articleId) => articles.find((article) => article.id === articleId))
  .filter((item): item is ArticleType => !!item);

export const xingAdvisoryArticles: ArticleType[] = [
  '299378.f17b0b',
  '299374.023738',
  '299373.ee8ba5',
  '299309.fc4678',
  '299298.70c4ae',
  '298993.bfb118',
  '298969.844888',
  '298959.725864',
  '298912.51f19b',
]
  .map((articleId) => articles.find((article) => article.id === articleId))
  .filter((item): item is ArticleType => !!item);
