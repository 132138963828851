import { Link } from '@reach/router';
import styled from 'styled-components';

import { Button } from '@xing-com/crate-lebenslauf-button';
import { mediaTiny } from '@xing-com/layout-tokens';
import { spaceS, xdlColorSelected } from '@xing-com/tokens';

const Container = styled.div`
  position: relative;

  @media ${mediaTiny} {
    display: none;
  }
`;

const SliderContainer = styled.section`
  overflow: hidden;
  position: relative;
`;

const Controls = styled.div`
  position: absolute;
  bottom: calc(100% / 3);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  width: calc(100% / 3 + 26px);
`;

const CTAButton = styled(Button)`
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
`;

const ActiveSlide = styled(Link)`
  flex: 0 0 calc(100% / 3);
  padding: 0 ${spaceS};
  opacity: 1;
  transform: scale(1);
  transition:
    transform 0.5s ease-out,
    opacity 0.5s ease-out;

  img {
    width: 100%;
    border-radius: 10px;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
    border: 2px solid ${xdlColorSelected};
  }
`;

const Slide = styled.button`
  flex: 0 0 calc(100% / 3);
  padding: 0 ${spaceS};
  background-color: transparent;
  border: 0 none;
  opacity: 0.7;
  transform: scale(0.8);
  transition:
    transform 0.5s ease-out,
    opacity 0.5s ease-out;
  cursor: pointer;

  img {
    width: 100%;
    border-radius: 10px;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
    border: 0px solid ${xdlColorSelected};
  }
`;

const Slides = styled.div<{ $translation: number }>`
  display: flex;
  transition: transform 0.5s ease-out;
  margin-bottom: -50px;
  ${({ $translation }) => `transform: translateX(${-$translation}%);`};

  // custom media queries as the existing tokens
  // does not fit to our need
  @media (min-width: 870px) {
    margin-bottom: -100px;
  }

  @media (min-width: 1200px) {
    margin-bottom: -200px;
  }
`;

export const Styled = {
  Container,
  Controls,
  CTAButton,
  Slide,
  ActiveSlide,
  Slides,
  SliderContainer,
};
