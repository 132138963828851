import { Frame } from '@xing-com/crate-lebenslauf-xinglets';
import type { msw, Xinglet } from '@xing-com/crate-xinglet';

import { Startpage } from './startpage';

export default class StartpageXinglet implements Xinglet {
  public async registerMocks(msw: msw): Promise<void> {
    (await import('./mocks')).setupMocking(msw);
  }
  public getComponent() {
    return (): JSX.Element => (
      <Frame noMainContentWrapperStyles>
        <Startpage />
      </Frame>
    );
  }
}
