import {
  VitaDocumentThemeTemplate,
  VitaDocumentThemeColor,
} from '@xing-com/crate-common-graphql-types';

import coverLetter1Preview from './assets/cover-letter-1-preview.jpg';
import template12x from './assets/template-1-2x.jpg';
import template1Preview from './assets/template-1-preview.jpg';
import template1 from './assets/template-1.jpg';
import template22x from './assets/template-2-2x.jpg';
import template2Preview from './assets/template-2-preview.jpg';
import template2 from './assets/template-2.jpg';
import template32x from './assets/template-3-2x.jpg';
import template3Preview from './assets/template-3-preview.jpg';
import template3 from './assets/template-3.jpg';
import template42x from './assets/template-4-2x.jpg';
import template4Preview from './assets/template-4-preview.jpg';
import template4 from './assets/template-4.jpg';
import template52x from './assets/template-5-2x.jpg';
import template5Preview from './assets/template-5-preview.jpg';
import template5 from './assets/template-5.jpg';
import template62x from './assets/template-6-2x.jpg';
import template6Preview from './assets/template-6-preview.jpg';
import template6 from './assets/template-6.jpg';
import template72X from './assets/template-7-2x.jpg';
import template7Preview from './assets/template-7-preview.jpg';
import template7 from './assets/template-7.jpg';

export type Template = {
  name: VitaDocumentThemeTemplate;
  title: string;
  description: string;
  imageUrl: string;
  imageUrl2x: string;
  previewImageUrl: string;
  bgColor: string;
  createWith: {
    color: VitaDocumentThemeColor;
    template: VitaDocumentThemeTemplate;
  };
  type: 'cv' | 'cover-letter';
};

export const templates: Template[] = [
  {
    name: VitaDocumentThemeTemplate.Frame,
    title: 'CRATE_LEBENSLAUF_TEMPLATES_FRAME_TITLE',
    description: 'CRATE_LEBENSLAUF_TEMPLATES_FRAME_DESCRIPTION',
    imageUrl: template4,
    imageUrl2x: template42x,
    previewImageUrl: template4Preview,
    bgColor: 'rgba(132,212,213,0.15)',
    createWith: {
      color: VitaDocumentThemeColor.DarkGreen,
      template: VitaDocumentThemeTemplate.Frame,
    },
    type: 'cv',
  },
  {
    name: VitaDocumentThemeTemplate.Modern,
    title: 'CRATE_LEBENSLAUF_TEMPLATES_MODERN_TITLE',
    description: 'CRATE_LEBENSLAUF_TEMPLATES_MODERN_DESCRIPTION',
    imageUrl: template2,
    imageUrl2x: template22x,
    previewImageUrl: template2Preview,
    bgColor: 'rgba(151,183,247,0.15)',
    createWith: {
      color: VitaDocumentThemeColor.LightBlue,
      template: VitaDocumentThemeTemplate.Modern,
    },
    type: 'cv',
  },
  {
    name: VitaDocumentThemeTemplate.Creative,
    title: 'CRATE_LEBENSLAUF_TEMPLATES_CREATIVE_TITLE',
    description: 'CRATE_LEBENSLAUF_TEMPLATES_CREATIVE_DESCRIPTION',
    imageUrl: template3,
    imageUrl2x: template32x,
    previewImageUrl: template3Preview,
    bgColor: 'rgba(202,211,226,0.15)',
    createWith: {
      color: VitaDocumentThemeColor.Grey,
      template: VitaDocumentThemeTemplate.Creative,
    },
    type: 'cv',
  },
  {
    name: VitaDocumentThemeTemplate.Traditional,
    title: 'CRATE_LEBENSLAUF_TEMPLATES_TRADITIONAL_TITLE',
    description: 'CRATE_LEBENSLAUF_TEMPLATES_TRADITIONAL_DESCRIPTION',
    imageUrl: template1,
    imageUrl2x: template12x,
    previewImageUrl: template1Preview,
    bgColor: 'rgba(255,141,154,0.15)',
    createWith: {
      color: VitaDocumentThemeColor.Pink,
      template: VitaDocumentThemeTemplate.Traditional,
    },
    type: 'cv',
  },
  {
    name: VitaDocumentThemeTemplate.Timeline,
    title: 'CRATE_LEBENSLAUF_TEMPLATES_TIMELINE_TITLE',
    description: 'CRATE_LEBENSLAUF_TEMPLATES_TIMELINE_DESCRIPTION',
    imageUrl: template5,
    imageUrl2x: template52x,
    previewImageUrl: template5Preview,
    bgColor: 'rgb(255, 222, 112, 0.15)',
    createWith: {
      color: VitaDocumentThemeColor.Brown,
      template: VitaDocumentThemeTemplate.Timeline,
    },
    type: 'cv',
  },
  {
    name: VitaDocumentThemeTemplate.Elegant,
    title: 'CRATE_LEBENSLAUF_TEMPLATES_ELEGANT_TITLE',
    description: 'CRATE_LEBENSLAUF_TEMPLATES_ELEGANT_DESCRIPTION',
    imageUrl: template6,
    imageUrl2x: template62x,
    previewImageUrl: template6Preview,
    bgColor: 'rgb(220, 236, 220, 0.15)',
    createWith: {
      color: VitaDocumentThemeColor.LightGreen,
      template: VitaDocumentThemeTemplate.Elegant,
    },
    type: 'cv',
  },
  {
    name: VitaDocumentThemeTemplate.Business,
    title: 'CRATE_LEBENSLAUF_TEMPLATES_ONE_CERO_ONE_TITLE',
    description: 'CRATE_LEBENSLAUF_TEMPLATES_ONE_CERO_ONE_DESCRIPTION',
    imageUrl: template7,
    imageUrl2x: template72X,
    previewImageUrl: template7Preview,
    bgColor: 'rgb(115, 234, 202, 0.15)',
    createWith: {
      color: VitaDocumentThemeColor.LightSea,
      template: VitaDocumentThemeTemplate.Business,
    },
    type: 'cv',
  },
  {
    name: VitaDocumentThemeTemplate.TraditionalCoverLetter,
    title: 'CRATE_LEBENSLAUF_TEMPLATES_COVER_LETTER_TRADITIONAL_TITLE',
    description:
      'CRATE_LEBENSLAUF_TEMPLATES_COVER_LETTER_TRADITIONAL_DESCRIPTION',
    imageUrl: coverLetter1Preview,
    imageUrl2x: coverLetter1Preview,
    previewImageUrl: coverLetter1Preview,
    bgColor: 'rgba(255,141,154,0.15)',
    createWith: {
      color: VitaDocumentThemeColor.DarkBlue,
      template: VitaDocumentThemeTemplate.TraditionalCoverLetter,
    },
    type: 'cover-letter',
  },
];
