import type * as React from 'react';

import {
  StyledCard,
  StyledImage,
  StyledHeadline,
  StyledBodyCopy,
  StyledFakeButton,
  StyledIcon,
} from './article-card.styled';

type ArticleCardType = {
  title: string;
  description: string;
  to: string;
  image: string;
  buttonCopy: string;
  isWide?: boolean;
};

export const ArticleCard: React.FC<ArticleCardType> = ({
  title,
  description,
  to,
  image,
  buttonCopy,
  isWide,
}) => (
  <StyledCard to={to} data-qa="lebenslauf-article-card" $isWide={isWide}>
    <StyledImage alt="" width="289" height="163" src={image} />
    <StyledHeadline size="xlarge">{title}</StyledHeadline>
    <StyledBodyCopy size="small">{description}</StyledBodyCopy>
    <StyledFakeButton size="small">
      {buttonCopy}
      <StyledIcon width="16" height="16" />
    </StyledFakeButton>
  </StyledCard>
);
