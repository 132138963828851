import type React from 'react';
import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { coverLetterArticles } from '@xing-com/crate-lebenslauf-articles';
import { ArticleCard, MetaData } from '@xing-com/crate-lebenslauf-components';
import { templates } from '@xing-com/crate-lebenslauf-constants';
import { useBasePath, useVitaTracking } from '@xing-com/crate-lebenslauf-hooks';
import { NativeAppsSection } from '@xing-com/crate-startpage-logged-out-native-apps-section';
import { scale360, spaceL } from '@xing-com/tokens';
import { Hero, BodyCopy, Headline } from '@xing-com/typography';

import CoverLetterBackgroundMobile from '../assets/cover-letter-bg-mobile.svg';
import CoverLetterBackground from '../assets/cover-letter-bg.svg';
import CoverLetterImage from '../assets/cover-letter.png';
import step1 from '../assets/step1.svg';
import step2 from '../assets/step2.svg';
import step3 from '../assets/step3.svg';
import step4 from '../assets/step4.svg';
import templatesPreviewImage1x from '../assets/templates-preview-1x.png';
import templatesPreviewImage2x from '../assets/templates-preview-2x.png';
import { Turntable } from '../turntable';
import {
  StyledSection,
  StyledCTA,
  StyledSteps,
  StyledStep,
  StyledIconCopy,
  StyledArticles,
  StyledCarousel,
  Wrapper,
  StyledFullWidthWrapper,
  StyledHeaderLeftWrapper,
  StyledHeader,
  StyledHeaderTemplate,
  StyledTemplate1,
  StyledTemplate2,
  StyledTemplate3,
  StyledStepImage,
  StyledStepsCTA,
  StyledTemplatesPreview,
  StyledCvWrapper,
  StyledAppSection,
  StyledCvCTA,
  StyledSeoSection,
} from './cover-letter-startpage.styled';

const metadata = {
  title:
    'Bewerbungsschreiben als PDF & Druckvorlage | Bewerbungsschreiben als PDF & Druckvorlage | anschreiben.com',
  description:
    'Professioneller als Word! – Schreibe jetzt mit Hilfe unserer Vorlagen und Designs kostenlos dein perfektes Bewerbungsschreiben auf anschreiben.com',
};

export const CoverLetterStartpage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { trackPageView } = useVitaTracking({ pageName: 'home' });
  const [currentBackgroundColor, setCurrentBackgroundColor] = useState(
    templates[2].bgColor
  );
  const basePath = useBasePath();

  useEffect(() => trackPageView(), [trackPageView]);

  return (
    <>
      <MetaData title={metadata.title} description={metadata.description} />

      <StyledFullWidthWrapper
        $mobileUrl={CoverLetterBackgroundMobile}
        $desktopUrl={CoverLetterBackground}
      >
        <StyledHeader>
          <StyledHeaderLeftWrapper>
            <Hero size="small" sizeWide="large">
              <FormattedMessage id="CRATE_ANSCHREIBEN_STARTPAGE_TITLE" />
            </Hero>
            <BodyCopy size="xlarge">
              <FormattedMessage id="CRATE_ANSCHREIBEN_STARTPAGE_SUBTITLE" />
            </BodyCopy>
            <StyledCTA
              variant="primary"
              size="medium"
              to={`${basePath}/anschreiben`} // TODO: add tracking params
            >
              <FormattedMessage id="CRATE_LEBENSLAUF_NAVIGATION_CREATE_COVER_LETTER" />
            </StyledCTA>
          </StyledHeaderLeftWrapper>
          <StyledHeaderTemplate>
            <StyledTemplate1 src={CoverLetterImage} width="406" />
            <StyledTemplate2 src={CoverLetterImage} width="406" />
            <StyledTemplate3 src={CoverLetterImage} width="406" />
          </StyledHeaderTemplate>
        </StyledHeader>
      </StyledFullWidthWrapper>

      <Wrapper>
        <StyledSection $isHeroSection>
          <Headline size="xxlarge" sizeWide="xxxlarge">
            <FormattedMessage id="CRATE_ANSCHREIBEN_STARTPAGE_3_STEPS_HEADLINE" />
          </Headline>
          <StyledSteps>
            <StyledStep>
              <StyledStepImage src={step1} />
              <StyledIconCopy size="medium" sizeWide="large">
                <FormattedMessage id="CRATE_ANSCHREIBEN_STARTPAGE_STEP_1" />
              </StyledIconCopy>
            </StyledStep>
            <StyledStep>
              <StyledStepImage src={step2} />
              <StyledIconCopy size="medium" sizeWide="large">
                <FormattedMessage id="CRATE_ANSCHREIBEN_STARTPAGE_STEP_2" />
              </StyledIconCopy>
            </StyledStep>
            <StyledStep>
              <StyledStepImage src={step3} />
              <StyledIconCopy size="medium" sizeWide="large">
                <FormattedMessage id="CRATE_ANSCHREIBEN_STARTPAGE_STEP_3" />
              </StyledIconCopy>
            </StyledStep>
            <StyledStep>
              <StyledStepImage src={step4} />
              <StyledIconCopy size="medium" sizeWide="large">
                <FormattedMessage id="CRATE_ANSCHREIBEN_STARTPAGE_STEP_4" />
              </StyledIconCopy>
            </StyledStep>
          </StyledSteps>
          <StyledStepsCTA
            variant="primary"
            size="medium"
            to={`${basePath}/anschreiben`} // TODO: add tracking params
          >
            <FormattedMessage id="CRATE_LEBENSLAUF_NAVIGATION_CREATE_COVER_LETTER" />
          </StyledStepsCTA>
        </StyledSection>

        <StyledSection $alignment="left">
          <Headline size="xxlarge" sizeWide="xxxlarge">
            <FormattedMessage id="CRATE_ANSCHREIBEN_STARTPAGE_TOP_ARTICLES_HEADLINE" />
          </Headline>
          <BodyCopy sizeWide="large">
            <FormattedMessage id="CRATE_ANSCHREIBEN_STARTPAGE_TOP_ARTICLES_SUBLINE" />
          </BodyCopy>
          <StyledCarousel
            itemMaxWidth={scale360}
            itemMinWidth={scale360}
            itemSpacing={spaceL}
            itemSpacingConfined={spaceL}
            itemSnapPosition="center"
          >
            {coverLetterArticles.map((article) => (
              <ArticleCard
                key={article.url}
                {...{
                  title: article.title,
                  description: article.description,
                  to: article.url,
                  image: article.image?.srcWide ?? '',
                  buttonCopy: formatMessage({
                    id: 'CRATE_LEBENSLAUF_STARTPAGE_ARTICLES_READ_MORE',
                  }),
                }}
              />
            ))}
          </StyledCarousel>
          <StyledArticles>
            {coverLetterArticles.map((article) => (
              <ArticleCard
                isWide
                key={article.url}
                {...{
                  title: article.title,
                  description: article.description,
                  to: article.url,
                  image: article.image?.srcWide ?? '',
                  buttonCopy: formatMessage({
                    id: 'CRATE_LEBENSLAUF_STARTPAGE_ARTICLES_READ_MORE',
                  }),
                }}
              />
            ))}
          </StyledArticles>
        </StyledSection>

        <StyledCvWrapper $backgroundColor={currentBackgroundColor}>
          <Headline size="xxxlarge">
            <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_TITLE" />
          </Headline>
          <BodyCopy size="large">
            <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_SUBTITLE" />
          </BodyCopy>
          <StyledCvCTA
            variant="primary"
            size="medium"
            to={`${basePath}/neu?sc_o=lebenslauf_home_create_cv_click&sc_o_PropActionOrigin=lebenslauf_home_body`}
          >
            <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_CTA" />
          </StyledCvCTA>
          <Turntable
            slides={templates.filter(({ type }) => type === 'cv')}
            setCurrentBackgroundColor={setCurrentBackgroundColor}
            hideCTA
          />
          <StyledTemplatesPreview
            src={templatesPreviewImage1x}
            srcSet={`${templatesPreviewImage1x} 1x, ${templatesPreviewImage2x} 2x`}
            loading="lazy"
            decoding="async"
            width="1000px"
            height="564px"
          />
        </StyledCvWrapper>

        <StyledAppSection>
          <NativeAppsSection
            trackClickStoreButton={() => {
              // TODO: add tracking
            }}
          />
        </StyledAppSection>

        <StyledSeoSection
          trackClickSerpCategory={() => {
            // TODO: add tracking
          }}
        />
      </Wrapper>
    </>
  );
};
