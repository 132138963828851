import { Link } from '@reach/router';
import styled from 'styled-components';

import { IconArrowRight } from '@xing-com/icons';
import {
  spaceS,
  spaceM,
  spaceL,
  xdlColorBackground,
  xdlColorBorderSoft,
} from '@xing-com/tokens';
import { BodyCopy, Headline } from '@xing-com/typography';

export const StyledCard = styled(Link)<{ $isWide?: boolean }>`
  background-color: ${xdlColorBackground};
  border: 2px solid ${xdlColorBorderSoft};
  border-radius: 8px;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  padding: 16px;
  position: relative;
  max-height: 394px;
  max-width: ${({ $isWide }) => ($isWide ? '400px' : '325px')};
  min-height: 394px;
  color: inherit;
`;

export const StyledImage = styled.img`
  border-radius: 3px;
  margin-bottom: ${spaceL};
  width: 100%;
  height: 163px;
  object-fit: cover;
  aspect-ratio: 16 / 9;
`;

export const StyledHeadline = styled(Headline)`
  margin-bottom: ${spaceM};
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export const StyledBodyCopy = styled(BodyCopy)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  flex-grow: 1;
`;

export const StyledFakeButton = styled(BodyCopy)`
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0;
`;

export const StyledIcon = styled(IconArrowRight)`
  margin-left: ${spaceS};
`;
