import type React from 'react';

import { useIsCoverLetterRoot } from '@xing-com/crate-lebenslauf-hooks';

import { CoverLetterStartpage } from './cover-letter';
import { CvStartpage } from './cv';

export const Startpage: React.FC = () => {
  const isCoverLetterRoot = useIsCoverLetterRoot();

  return isCoverLetterRoot ? <CoverLetterStartpage /> : <CvStartpage />;
};
