import type * as React from 'react';

import type { RuntimeHost, Xinglet } from '@xing-com/crate-xinglet';
import { XingletLoader } from '@xing-com/crate-xinglet';

export interface XingletInterface<Props> extends Xinglet {
  getComponent: (host: RuntimeHost) => React.ComponentType<Props>;
}

type XingletNoProps = Record<string, never>;
export function XingletWrapper<Props = XingletNoProps>(
  name: string,
  fallback?: React.ReactElement
): React.ComponentType<Props> {
  return (props: Props) => (
    <XingletLoader name={name} fallback={fallback} {...props} />
  );
}
