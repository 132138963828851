import styled from 'styled-components';

import { Carousel } from '@xing-com/carousel';
import { Button } from '@xing-com/crate-lebenslauf-button';
import { mainContentWrapperStyles } from '@xing-com/crate-lebenslauf-components';
import { SeoSection } from '@xing-com/crate-startpage-logged-out-seo-section';
import {
  mediaConfined,
  mediaWideNavless,
  mediaTiny,
} from '@xing-com/layout-tokens';
import {
  spaceS,
  spaceM,
  spaceL,
  spaceXL,
  spaceXXL,
  space3XL,
  space5XL,
  space4XL,
  space6XL,
  scale240,
  scale260,
  scale280,
  scale340,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const Wrapper = styled.div`
  ${mainContentWrapperStyles}
  padding-top: 0;
  padding-bottom: 0;

  @media ${mediaConfined} {
    padding-top: 0;
  }

  @media ${mediaWideNavless} {
    padding-top: 0;
  }
`;

export const StyledSection = styled.section<{
  $alignment?: string;
  $isHeroSection?: boolean;
}>`
  max-width: ${({ $isHeroSection }) => ($isHeroSection ? '1500px' : '1256px')};
  margin: 0 auto;
  padding: ${spaceXXL} 0;

  ${({ $alignment }) => `
    text-align: ${$alignment ? $alignment : 'center'};
  `}

  @media ${mediaWideNavless} {
    padding: ${space3XL} 0;
  }
`;

export const StyledCTA = styled(Button)`
  @media ${mediaWideNavless} {
    margin-top: ${spaceXXL};
    margin-bottom: ${spaceXXL};
  }
`;

export const StyledCvCTA = styled(Button)`
  margin-top: ${spaceL};
  margin-bottom: ${spaceXL};

  @media ${mediaWideNavless} {
    margin-bottom: ${space3XL};
  }
`;

export const StyledSteps = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: ${spaceXXL};
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${spaceXL};

  @media ${mediaWideNavless} {
    gap: ${space5XL};
  }
`;

export const StyledStep = styled.li`
  display: inline-flex;
  padding: 0 ${spaceS};
  max-width: 50%;
  align-items: center;
  flex-direction: column;
  max-width: 240px;

  @media ${mediaWideNavless} {
    max-width: ${scale340};
  }
`;

export const StyledIconCopy = styled(BodyCopy)`
  margin-top: ${spaceL};
`;

export const StyledStepImage = styled.img`
  width: 240px;
  height: 240px;
`;

export const StyledStepsCTA = styled(Button)`
  margin-top: ${spaceXXL};
`;

export const StyledArticles = styled.div`
  margin: ${spaceL} -${spaceM} -${spaceM} -${spaceM};
  display: none;

  @media ${mediaWideNavless} {
    display: block;
  }

  > * {
    margin: ${spaceM};
  }
`;

export const StyledCarousel = styled(Carousel)`
  @media (max-width: 1024px) {
    margin: 0 -${spaceL};
    padding-left: ${spaceL};
    padding-right: ${spaceL};
  }

  @media ${mediaWideNavless} {
    display: none;
  }
`;

export const StyledFullWidthWrapper = styled.div<{
  $desktopUrl: string;
  $mobileUrl: string;
}>`
  background: url(${({ $mobileUrl }) => $mobileUrl}) top right
    rgba(151, 183, 247, 0.15);
  background-size: cover;
  padding: 0 ${spaceL};
  padding-top: ${scale240};

  @media ${mediaConfined} {
    background-image: url(${({ $desktopUrl }) => $desktopUrl});
    padding-top: ${scale260};
  }

  @media ${mediaWideNavless} {
    padding-top: ${scale280};
  }
`;

export const StyledHeader = styled.section`
  max-width: 1500px;
  margin: 0 auto;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: ${spaceXL};
  flex-wrap: wrap;
  overflow: hidden;
  position: relative;

  @media ${mediaWideNavless} {
    padding: 0 ${space4XL};
    flex-wrap: nowrap;
    justify-content: space-evenly;
    text-align: left;
  }
`;

export const StyledHeaderLeftWrapper = styled.div`
  max-width: 622px;
  margin-bottom: 250px;

  @media ${mediaWideNavless} {
    margin-bottom: 0;
  }
`;

export const StyledHeaderTemplate = styled.div`
  position: absolute;
  bottom: -235px;

  @media ${mediaWideNavless} {
    position: relative;
    margin-bottom: ${space6XL};
    bottom: 0;
  }
`;

export const StyledTemplate1 = styled.img`
  margin-right: ${spaceXXL};
  width: 319px;
  height: 452px;

  @media ${mediaWideNavless} {
    width: initial;
    height: auto;
  }
`;

export const StyledTemplate2 = styled.img`
  position: absolute;
  top: ${spaceXL};
  left: ${spaceXL};
  width: 319px;
  height: 452px;

  @media ${mediaWideNavless} {
    width: initial;
    height: auto;
  }
`;

export const StyledTemplate3 = styled.img`
  position: absolute;
  top: ${space3XL};
  left: ${space3XL};
  width: 319px;
  height: 452px;

  @media ${mediaWideNavless} {
    width: initial;
    height: auto;
  }
`;

export const StyledTemplatesPreview = styled.img`
  width: 100%;
  height: auto;
  display: none;

  @media ${mediaTiny} {
    display: block;
  }
`;

export const StyledCvWrapper = styled.div<{ $backgroundColor: string }>`
  position: relative;
  max-width: 1356px;
  margin: 0 auto;
  text-align: center;
  margin-top: ${space3XL};
  margin-bottom: ${space4XL};

  :before {
    position: absolute;
    content: '';
    top: -32px;
    left: 50%;
    height: calc(100% + 32px);
    transition: background-color 200ms;
    width: 100vw;
    transform: translateX(-50%);
    background-color: ${({ $backgroundColor }) => $backgroundColor};
  }

  @media ${mediaWideNavless} {
    margin-top: ${space4XL};
    margin-bottom: ${space5XL};

    :before {
      top: -48px;
      height: calc(100% + 48px);
    }
  }
`;

export const StyledAppSection = styled.div`
  max-width: 1256px;
  margin: 0 auto;
`;

export const StyledSeoSection = styled(SeoSection)`
  max-width: 1256px;
  margin: 0 auto;
`;
