import type * as React from 'react';
import styled from 'styled-components';

import {
  Button as BreweryButton,
  IconButton as BreweryIconButton,
  TextButton as BreweryTextButton,
  type ButtonProps,
} from '@xing-com/button';
import type { IconButtonProps, TextButtonProps } from '@xing-com/button';
import {
  xdlColorControlFillTertiary,
  xdlColorDisabledSoft,
  xdlColorHoverTertiary,
  xdlColorSelected,
  xdlColorSelectedSecondary,
  xdlColorText,
  xdlColorTextTertiary,
  xdlPaletteMint05,
  xdlPaletteMint08,
} from '@xing-com/tokens';

export type { TextButtonProps, ButtonProps, IconButtonProps };

const StyledPrimaryBreweryButton = styled(BreweryButton)`
  &&::after {
    background-color: ${xdlPaletteMint05};
  }

  &&:hover {
    &::after {
      background-color: ${xdlPaletteMint08};
    }
  }

  &&:active {
    &::after {
      background-color: ${xdlColorSelected};
    }
  }
  &&:disabled {
    &::after {
      background-color: ${xdlColorDisabledSoft};
    }
  }
`;

const StyledTertiaryBreweryIconButton = styled(BreweryIconButton)`
  &&::after {
    background-color: ${xdlColorControlFillTertiary};
  }
  &&:hover {
    &::after {
      background-color: ${xdlColorHoverTertiary};
    }
  }
  &&:active {
    &::after {
      background-color: ${xdlColorControlFillTertiary};
    }
  }
`;

const StyledTransparentBreweryIconButton = styled(BreweryIconButton)`
  color: ${xdlColorTextTertiary};
  &&::after {
    background-color: transparent;
  }

  &&:hover {
    color: ${xdlColorText};

    &::after {
      background-color: transparent;
    }
  }

  &&:active {
    color: ${xdlColorSelectedSecondary};

    &::after {
      background-color: transparent;
    }
  }
`;

export const Button: React.FC<ButtonProps> = (props) => {
  if (props.variant === 'primary') {
    return <StyledPrimaryBreweryButton {...props} />;
  }

  return <BreweryButton {...props} />;
};

export const TextButton: React.FC<TextButtonProps> = (props) => {
  return <BreweryTextButton {...props} />;
};

export const IconButton: React.FC<
  IconButtonProps & { iconButtonType?: 'transparent' | 'tertiary' }
> = ({ iconButtonType, ...props }) => {
  if (iconButtonType === 'transparent') {
    return <StyledTransparentBreweryIconButton {...props} />;
  }
  return <StyledTertiaryBreweryIconButton {...props} />;
};
