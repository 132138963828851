import type { ArticleType } from './articles';
import cagdasAygun from './assets/cagdas-aygun.jpg';
import carlosBarquero from './assets/carlos-barquero.jpg';
import delmainDonson from './assets/delmaine-donson.jpg';
import flashpop from './assets/flashpop.jpg';
import westend61 from './assets/westend61.jpg';
import yaroslavOlieinikov from './assets/yaroslav-olieinikov.jpg';

export const coverLetterArticles: ArticleType[] = [
  {
    // slug: 'anschreiben-mit-luecken-im-lebenslauf-tipps-zur-geschickten-formulierung',
    id: '315156.b0f8f6',
    url: '/ratgeber/anschreiben-mit-luecken-im-lebenslauf',
    title: 'Anschreiben mit Lücken im Lebenslauf: So überzeugst Du!',
    description:
      'Unsere Tipps zeigen, wie du Lücken im Lebenslauf positiv formulierst und als Stärke im Anschreiben präsentierst. So überzeugst Du!',
    image: {
      srcWide: carlosBarquero,
    },
  },
  {
    // slug: 'bewerbungsanschreiben-wie-du-deine-motivation-ueberzeugend-darstellst',
    id: '315160.bb1d97',
    url: '/ratgeber/ueberzeugendes-bewerbungsanschreiben',
    title: 'Bewerbungsanschreiben: Überzeuge mit deiner Motivation!',
    description:
      'Erfahre, wie du dein Bewerbungsanschreiben mit überzeugender Motivation zum Erfolg machst und dich von der Masse abhebst.',
    image: {
      srcWide: flashpop,
    },
  },
  {
    // slug: 'die-haeufigsten-fehler-im-anschreiben-und-wie-du-sie-vermeidest',
    id: '315161.b27feb',
    url: '/ratgeber/fehler-im-anschreiben ',
    title: 'Die häufigsten Fehler im Anschreiben vermeiden',
    description:
      'Erfahre, wie du die häufigsten Fehler im Anschreiben vermeiden kannst und erhöhe deine Chancen auf eine erfolgreiche Bewerbung.',
    image: {
      srcWide: cagdasAygun,
    },
  },
  {
    // slug: 'anschreiben-auf-englisch-dos-and-don-ts-fuer-deinen-internationalen-job-ein-ansc',
    id: '315166.8fdbcd',
    url: '/ratgeber/anschreiben-auf-englisch',
    title: "Anschreiben auf Englisch: Dos and Don'ts für Jobs",
    description:
      "Erfahre die Dos and Don'ts für ein überzeugendes Anschreiben auf Englisch, um sich international zu bewerben. Jetzt informieren und durchstarten!",
    image: {
      srcWide: yaroslavOlieinikov,
    },
  },
  {
    // slug: 'anschreiben-ohne-berufserfahrung-so-punktest-du-trotzdem',
    id: '315167.7da66b',
    url: '/ratgeber/anschreiben-ohne-berufserfahrung',
    title: 'Anschreiben ohne Berufserfahrung: So überzeugst Du',
    description:
      'Erfahre, wie du ohne Berufserfahrung ein überzeugendes Anschreiben erstellst und erfolgreich in den Beruf einsteigst.',
    image: {
      srcWide: delmainDonson,
    },
  },
  {
    // slug: 'der-perfekte-einstiegssatz-fuer-dein-anschreiben-so-ueberzeugst-du-sofort-1',
    id: '315174.b69794',
    url: '/ratgeber/einstiegssatz-fuer-dein-anschreiben',
    title: 'Der perfekte Einstiegssatz für dein Anschreiben!',
    description:
      'Entdecke, wie du mit dem perfekten Einstiegssatz im Anschreiben sofort überzeugst. Tipps & Beispiele für einen gelungenen Start.',
    image: {
      srcWide: westend61,
    },
  },
];
