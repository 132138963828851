import { useState, useEffect } from 'react';
import type React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { IconButton } from '@xing-com/crate-lebenslauf-button';
import type { Template } from '@xing-com/crate-lebenslauf-constants';
import { useBasePath, useVitaTracking } from '@xing-com/crate-lebenslauf-hooks';
import { IconArrowLeft, IconArrowRight } from '@xing-com/icons';
import { Image } from '@xing-com/image';

import { Styled } from './turntable.styled';

const SHOW = 3;

export const Turntable: React.FC<{
  slides: Template[];
  setCurrentBackgroundColor: React.Dispatch<React.SetStateAction<string>>;
  hideCTA?: boolean;
}> = ({ slides, setCurrentBackgroundColor, hideCTA }) => {
  const intl = useIntl();
  const basePath = useBasePath();
  const { trackInteraction } = useVitaTracking();
  const [slidesToRender, setSlidesToRender] = useState([...slides, ...slides]);
  const [currentIndex, setCurrentIndex] = useState(1);
  const templateName = slidesToRender[currentIndex + 1].name;
  const activeTemplateLink = `${basePath}/neu#${slidesToRender[
    currentIndex + 1
  ].createWith.template.toLowerCase()}-${slidesToRender[
    currentIndex + 1
  ].createWith.color.toLowerCase()}`;

  const handlePrevClick = (): void =>
    setCurrentIndex((prevState) => prevState - 1);

  const handleNextClick = (): void =>
    setCurrentIndex((prevState) => prevState + 1);

  const handleCTAClick = (): void => {
    trackInteraction('lebenslauf_home_select_template_click', {
      PropContextDimension1: `lebenslauf_template_id_${templateName}`,
    });
  };

  useEffect(() => {
    if (currentIndex === slidesToRender.length - 2) {
      slides.forEach((slide) =>
        setSlidesToRender((prevState) => [...prevState, slide])
      );
    }
  }, [currentIndex, slides, slidesToRender.length]);

  useEffect(() => {
    if (currentIndex === 0) {
      slides
        .slice()
        .reverse()
        .forEach((slide) =>
          setSlidesToRender((prevState) => [slide, ...prevState])
        );
      setCurrentIndex(slides.length);
    }
  }, [currentIndex, slides]);

  return (
    <Styled.Container>
      <Styled.SliderContainer
        role="listbox"
        aria-roledescription="carousel"
        aria-label="select template"
      >
        <Styled.Slides $translation={currentIndex * (100 / SHOW)}>
          {slidesToRender.map((slide, index) => {
            if (currentIndex === index - 1) {
              setCurrentBackgroundColor(slide.bgColor);

              return (
                <Styled.ActiveSlide
                  to={activeTemplateLink}
                  onClick={handleCTAClick}
                  key={index}
                >
                  <Image
                    src={slide.imageUrl}
                    // @ts-expect-error FIXME: SC6
                    srcSet={`${slide.imageUrl} 1x, ${slide.imageUrl2x} 2x`}
                    loading="lazy"
                    width="595"
                    height="842"
                    alt={slide.title}
                  />
                </Styled.ActiveSlide>
              );
            }
            return (
              <Styled.Slide
                onClick={
                  currentIndex === index ? handlePrevClick : handleNextClick
                }
                key={index}
              >
                <Image
                  src={slide.imageUrl}
                  // @ts-expect-error FIXME: SC6
                  srcSet={`${slide.imageUrl} 1x, ${slide.imageUrl2x} 2x`}
                  loading="lazy"
                  width="595"
                  height="842"
                  alt={slide.title}
                />
              </Styled.Slide>
            );
          })}
        </Styled.Slides>
        <Styled.Controls aria-label="controls">
          <IconButton
            icon={IconArrowLeft}
            onClick={handlePrevClick}
            role="option"
            size="medium"
            // @ts-expect-error FIXME: SC6
            tabIndex="0"
            variant="tertiary"
            aria-label={intl.formatMessage({
              id: 'CRATE_VITA_STARTPAGE_PREVIOUS_TEMPLATE_BUTTON',
            })}
          />
          <IconButton
            icon={IconArrowRight}
            onClick={handleNextClick}
            role="option"
            size="medium"
            // @ts-expect-error FIXME: SC6
            variant="tertiary"
            aria-label={intl.formatMessage({
              id: 'CRATE_VITA_STARTPAGE_NEXT_TEMPLATE_BUTTON',
            })}
          />
        </Styled.Controls>
      </Styled.SliderContainer>
      {!hideCTA && (
        <Styled.CTAButton
          variant="primary"
          size="medium"
          to={activeTemplateLink}
          onClick={handleCTAClick}
        >
          <FormattedMessage id="CRATE_VITA_STARTPAGE_TURNTABLE_CTA" />
        </Styled.CTAButton>
      )}
    </Styled.Container>
  );
};
