import { useIsCrateApp } from './use-is-crate-app';

export const useBasePath = (): string => {
  const isCrateApp = useIsCrateApp();

  if (isCrateApp) {
    return '/lebenslauf';
  }

  return '';
};
